import { NextRouter } from 'next/router';

export const languages = [
  { name: 'Global', shortName: 'en' },
  // { name: 'Deutschland', shortName: 'de' }, // Disabled temporary
  { name: 'España', shortName: 'es' },
  { name: '中国', shortName: 'zh-CN' },
  { name: 'Brasil', shortName: 'pt-BR' },
  // { name: 'netherlands', shortName: 'nl-NL' },  // Disabled temporary
];

export const handleLanguage = (router: NextRouter, shortName: string) => {
  if (typeof window === 'undefined') return;

  const currentDomain = window.location.hostname.replace('www.', '');
  const isOnGlobalDomain = currentDomain === 'designbridge.com';
  const isOnChineseDomain = currentDomain === 'designbridge.com.cn';

  if (isOnChineseDomain && shortName !== 'zh-CN') {
    window.location.href = `https://www.designbridge.com${
      shortName === 'en' ? '' : '/' + shortName
    }${router.asPath}`;
    return;
  }

  if (isOnGlobalDomain && shortName === 'zh-CN') {
    window.location.href = `https://designbridge.com.cn${router.asPath}`;
    return;
  }

  router.push(router.asPath, router.asPath, { locale: shortName });
};

import create from 'zustand';

import { INITIAL_DATA } from 'components/Layout/Layout.utils';
import {
  FooterTypes,
  GlobalSearchResultsTypes,
} from 'utils/sharedStrapiQueries/sharedTypes';

export interface GlobalStoreType {
  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;

  isSearchOverlayOpen: boolean;
  setIsSearchOverlayOpen: (state: boolean) => void;

  shouldRotateDevice: boolean;
  setRotateDevice: (state: boolean) => void;

  isNavRedirecting: boolean;
  setIsNavRedirecting: (state: boolean) => void;

  isMenuOpen: boolean;
  setIsMenuOpen: (state: boolean) => void;

  isEnteringWorkPageContent: boolean;
  setIsEnteringWorkPageContent: (state: boolean) => void;

  isHeaderTextVisible: boolean;
  setIsHeaderTextVisible: (state: boolean) => void;

  headerText: {
    normal: string;
    colored: string;
    default: string;
    color: string;
  };
  setHeaderText: (state: {
    normal?: string;
    colored?: string;
    default?: string;
    color?: string;
  }) => void;
  resetHeaderText: () => void;

  isGridView: boolean;
  setIsGridView: (state: boolean) => void;

  isCopyLogo: boolean;
  setIsCopyLogo: (state: boolean) => void;

  activeFilter: string;
  setActiveFilter: (state: string) => void;

  searchResults: GlobalSearchResultsTypes[];
  setSearchResults: (results: GlobalSearchResultsTypes[]) => void;

  cookiesConfirmation: boolean;
  setCookiesConfirmation: (state: boolean) => void;

  locationData: FooterTypes;
  setLocationData: (state: FooterTypes) => void;
}

export const DEFAULT_HEADER_TEXT = {
  normal: '',
  colored: '',
  default: 'Design',
  color: '',
};

const useGlobalStore = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      isWindowTooSmall: false,
      setWindowTooSmall: state => {
        set(() => ({
          isWindowTooSmall: state,
        }));
      },

      isSearchOverlayOpen: false,
      setIsSearchOverlayOpen: state => {
        set(() => ({
          isSearchOverlayOpen: state,
        }));
      },

      shouldRotateDevice: false,
      setRotateDevice: state => {
        set(() => ({
          shouldRotateDevice: state,
        }));
      },

      isNavRedirecting: false,
      setIsNavRedirecting: state => {
        set(() => ({
          isNavRedirecting: state,
        }));
      },

      isMenuOpen: false,
      setIsMenuOpen: state => {
        set(() => ({
          isMenuOpen: state,
        }));
      },

      isEnteringWorkPageContent: false,
      setIsEnteringWorkPageContent: state => {
        set(() => ({
          isEnteringWorkPageContent: state,
        }));
      },

      isHeaderTextVisible: true,
      setIsHeaderTextVisible: state => {
        set(() => ({
          isHeaderTextVisible: state,
        }));
      },

      headerText: DEFAULT_HEADER_TEXT,
      setHeaderText: state => {
        set(() => ({
          headerText: {
            normal: state.normal,
            colored: state.colored,
            default: state.default,
            color: state.color,
          },
        }));
      },
      resetHeaderText: () => {
        set(() => ({ headerText: DEFAULT_HEADER_TEXT }));
      },

      isGridView: true,
      setIsGridView: state => {
        set(() => ({
          isGridView: state,
        }));
      },

      isCopyLogo: false,
      setIsCopyLogo: state => {
        set(() => ({
          isCopyLogo: state,
        }));
      },

      activeFilter: '',
      setActiveFilter: state => {
        set(() => ({
          activeFilter: state,
        }));
      },

      searchResults: [],
      setSearchResults: results => {
        set(() => ({
          searchResults: results,
        }));
      },

      cookiesConfirmation: false,
      setCookiesConfirmation: state => {
        set(() => ({
          cookiesConfirmation: state,
        }));
      },

      locationData: INITIAL_DATA,
      setLocationData: state => {
        set(() => ({
          locationData: state,
        }));
      },
    };
  }
);

export default useGlobalStore;

import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

class Analytics {
  static TRACKING_ID = 'GTM-K7HHTZZ';

  private cookieAnalytics = Cookies.get('dbp.analytics');
  private cookieCondition =
    this.cookieAnalytics && this.cookieAnalytics.toLowerCase() === 'true';

  _initializeTracker() {
    if (this.cookieCondition) {
      TagManager.initialize({
        gtmId: Analytics.TRACKING_ID,
      });
    } else console.log('### Analytics disabled ###');
  }

  /**
   * Tracks events in Google Tag Manager.
   * @param {string} event - The name of the event (e.g., 'button_click').
   * @param {object} data - Additional event data (e.g., { category: 'UI', action: 'click', label: 'signup_button' }).
   */
  trackEvent(event, data = {}) {
    if (this.cookieCondition) {
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...data,
        },
      });
      console.log(`### Event tracked: ${event}`, data);
    } else {
      console.log('### Analytics disabled: Event not tracked ###');
    }
  }

  trackPageView(path) {
    this.trackEvent('page_view_contact', { path });
  }

  trackEmailCopy(label) {
    this.trackEvent('copy_email', { label });
  }

  trackLinkedInClick(profileName) {
    this.trackEvent('linkedin_click', { profile: profileName });
  }

  trackArticleClick(articleTitle) {
    this.trackEvent('article_click', { title: articleTitle });
  }
}

export default Analytics;
